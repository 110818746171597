@use 'variables' as v;



html {
    font-size: 62.5%;
    box-sizing: border-box;
    height: 100%;
}
body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $blanco;
    color: $black;
}
*, *:before, *:after {
    box-sizing: inherit;
}
body {
    font-family: $fuente_principal;
    font-size: 1.6rem;
}
.contenedor {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
}
a {
    text-decoration: none;
}

img {
    max-width: 100%;
}