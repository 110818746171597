.container {
  margin-left: 30rem;
  display: flex;
 
  /* Prevent scrolling on the entire container */
}


.sidebarIngreso {
  width: 20%;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Align items at the top */
  position: sticky;
  /* Keep the sidebar fixed */
  top: 0;
  height: 100vh;
  /* Full viewport height */
}

.toggle-btn {
  padding: 3.5rem 0;
  margin: 1rem 0;
  font-size: 1.8rem;
  border: none;
  color: white;
  cursor: pointer;

  &.green {
    background-color: #4caf50;
  }

  &.yellow {
    background-color: #ffca28;
  }
}

.form-container {
  width: 100%;
  padding: 2rem;
  margin-bottom: 2rem;
  overflow-y: auto;
  /* Make the form scrollable */
  max-height: 100vh;
  /* Ensure it doesn't exceed the viewport height */
}

.form-section {


  margin-bottom: 2rem;

  h2 {
    margin-bottom: 1rem;
  }

  &.green-background {
    background-color: #e8f5e9;
    padding: 2rem;
    border-radius: .5rem;
  }

  &.yellow-background {
    background-color: #fffde7;
    padding: 2rem;
    border-radius: .5rem;
  }

  .generar-codigo {
    display: grid;
    grid-template-columns: 2fr 1fr .5fr;
    gap: 1rem;

    .action-btn {
    
      background-color: #d32f2f;
      color: white;
      border: none;
      cursor: pointer;
      padding: 1.5rem;
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }
  }

  .actualizar_codigo {
    display: grid;
    grid-template-columns: 1fr .15fr;
    gap: 1rem;
  }
}

.localizacion_producto {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
}

.action-btn {
  background-color: #d32f2f;
  color: white;
  border: none;
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  &.negro {
    margin-top: 3rem !important;
    background-color: #333 !important;
  }
}

#fileNameVarios, #fileNameActualizar, #fileNameNuevo {
  color: red ;
  
}
.div_nombre_varios {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr .25fr !important;
  #fileNameVarios {
    display: flex; 
    align-items: center
  }

}
#clearFileBtn, #clearFileBtnVarios, #clearFileBtnActualizar, #clearFileBtnNuevo {

  cursor: pointer;
   color: red; 
   font-size: 4rem;
   margin: 0;
}
.comprobante_pago {
  display: grid;
  grid-template-columns: .75fr 1fr .25fr;
  align-items: center!important;
  justify-content: center!important;
}
/* New CSS for radio buttons */
.radio-group {
  margin-top: 3rem;
  display: flex;
  gap: 2rem;
  margin-bottom: 3rem;
  justify-content: center;
  align-items: center;

  label {
    font-size: 2rem;
    margin-left: .1rem;
    /* Reduces space between the radio button and label */
  }

  input[type="radio"] {
    margin: 0;
    width: 5rem;
    height: 2rem;
    vertical-align: middle;
  }
}

input,
select {
  display: block;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
}

#barcodePrint {
  display: none;
  margin-top: 10mm;
  transform: rotate(90deg);
  margin-left: auto;
  margin-right: auto;
}

@page {
  size: 5mm 62mm;
  margin: 0;
}

@media print {

  /* Hide everything in the body except the barcode element */
  .container,
  .sidebar {
    display: none;
  }

  #barcodePrint {
    display: block;
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}

.small-btn {
  font-size: 0.8em;
  margin-left: 10px;
  padding: 5px 10px;
}

i {
  font-size: 2rem;
}

#customFileUploadNuevo,
#customFileUploadActualizar {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

#customFileUpload:hover,
#customFileUploadActualizar:hover {
  background-color: #0056b3;
}

#fileNameNuevo,
#fileNameActualizar {
  margin-left: 10px;
  font-size: 16px;
}

.forma-dimesion {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  input {
    width: 1.5rem;
    margin: 0;
    height: 1.5rem;
  }
  label {
    font-size: 1.8rem;
  }
}
.dimension {
  overflow: hidden;
  position: sticky;
  /* Keep the sidebar fixed */
  background-color: $blanco;
  top: 0;
}

.dimension-form {
  width: 80%;
}

.car_ingreso {
  
  display: none;
  h3{
    position: sticky;
    /* Keep the sidebar fixed */
    background-color: $blanco;
    top: 0;
  }
  .product-list {
    padding: 0;
    margin: 0;
    list-style-type: none; /* Remove default bullet points */
  }
  
  .product-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;
    border-bottom: 1px solid #ccc; /* Optional: Add a separator between items */
  }
  
  .bullet {
    font-size: 24px;
    margin-right: 10px;
    color: #333; /* Bullet color */
  }
  
  .product-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column; /* Stack the name and subtitle vertically */
  }
  
  .product-name {
    font-size: 18px; /* Larger font size for the main title */
    font-weight: bold;
  }
  
  .product-subtitle {
    font-size: 14px; /* Smaller font size for the subtitle */
    color: #666; /* Subtle color for the subtitle */
  }
  
  .delete-icon {
    margin-left: 15px;
    cursor: pointer;
    font-size: 18px; /* Size of the delete icon */
    color: red; /* Color of the delete icon */
  }
  button {
    position: sticky;
    /* Keep the sidebar fixed */
    bottom: 0;
  }
}
.car_ingreso {
  .action-btn {
    margin-top: 1rem;
  }
}