

h1,
h2,
h3 {
    font-family: $fuente_principal;
    margin: 0 0 calc($separacion / 2) 0;
    font-weight: 900;
    color: $black;
    text-align: center;
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 2.6rem;
}

h3 {
    font-size: 3rem;
    text-align: center;
}

p {
    color: $black;
    font-size: 2rem;
}