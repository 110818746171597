.familias-table {
    display: flex;
    flex-direction: column;
    height: 100%; /* Set the desired height */
    overflow-y: auto; /* Enables scrolling if content exceeds height */
}
.container.familias {
    display: flex;
    flex-direction: column;
    padding: 2rem 10rem;
}
.row {
    margin-bottom: 2rem;


}
.familias_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.plus-icon {
    cursor: pointer;
    font-size: 24px;
}
.subfamily-form {
    display: none;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 10px;
    input {
        margin: 0!important;
    }
    button {
        margin: 0!important;
    }
}
.subfamily-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}


.form-section.familias{
    .localizacion_producto {
        margin-top: 3rem !important;
        grid-template-columns: 1fr .5fr !important;
        gap: 2rem !important;
    }
}