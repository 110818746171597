.sidebar {
    overflow-y: auto;
    width: 30rem;
    background-color: #e0d5ca;
    height: 100vh;
    position: fixed;
    padding: 2rem 0 0 2rem;
    display: flex;
    flex-direction: column;

    ul {
        list-style-type: none;
        padding: 0;
        flex-grow: 1;

        li {
            @include list-item-style;
        }
    }

    .dropdown {
        margin-bottom: 2rem;

        select {
            font-weight: $bold;
            width: 100%;
            padding: .8rem;
            font-size: 1.4rem;
            border-radius: .4rem;
            border: .1rem solid #ccc;
            background-color: transparent;
        }
    }

    .logout {
        font-size: 1.4rem;
        color: #333;
        margin-top: 2rem;

        li {
            list-style-type: none!important;
            @include list-item-style;
        }
    }
    .circular-image {
        width: 20rem;
        /* Adjust the size as needed */
        height: 20rem;
        /* Adjust the size as needed */
        background-color: #E0D5CA;
        /* Adjust the background color to match your design */
        border-radius: 50%;
        object-fit: cover;
        display: block;
        margin: 1rem auto 3rem auto;
    }


}


.sidebar .dropdown-toggle_retiros, .sidebar .dropdown-toggle_ingresos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: $negro;
    text-decoration: none;
    width: 100%;
    line-height: 1.2; /* Adjust line-height for consistent text height */
    cursor: pointer;
    border-radius: 4px;
}

.sidebar .dropdown-icon {
    margin-left: auto;
}

.sidebar .dropdown-menu {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #e0d5ca;; /* Adjust the color as needed */
    width: 100%;
    position: relative;
}

.sidebar .dropdown-menu li {
    padding: 5px 15px; /* Reduced padding for smaller height */
    line-height: 1.2; /* Adjust line-height for reduced height */
    margin: 0;
}

.sidebar .dropdown-menu li a {
    text-decoration: none;
    color: $negro;
    display: block;
}

.sidebar .dropdown-menu li a:hover {
    background-color:  $rojo;
}

.sidebar .dropdown_retiros.open .dropdown-menu, .sidebar .dropdown_ingresos.open .dropdown-menu{
    display: block;
}
.dropdown_retiros.open.active, .dropdown_ingresos.open.active {
    background-color: $rojo;
    color: white;

    .dropdown-toggle_retiros, .dropdown-toggle_ingresos {
        color: $blanco;
    }
}


