.container.compras {
    display: flex;
    flex-direction: column;
    padding: 2rem 10rem;


    form {
        .localizacion_producto {
            margin-top: 3rem !important;
            grid-template-columns: 1fr .5fr !important;
            gap: 2rem !important;
        }
    }
    .table-container {
        margin-top: 2rem !important;
    }
}