// Fuentes
$fuente_principal: 'Poppins', sans-serif;

// Tamaño de Media Queries
$telefono: 480px;
$tablet: 768px;
$desktop: 1024px;

// Colores

$rojoDegradel: #B4043C;
$grisDegradel: #5C5C5C;

$rojo: #B4043C;
$verde:#469B1E;
$amarillo: #F8D442;
$azul: #3204B4;
$negro: #1a1b15;

$blanco: #FFFFFF;
$gris: #e1e1e1;

$separacion: 5rem;

// Fuentes
$delgada: 300;
$regular : 400;
$bold: 700;
$black: 900