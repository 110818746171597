

// Apply styles for the custom-background class on the body element
.custom-background {
    margin: 0;
    padding: 0;
    background: linear-gradient(45deg, $rojoDegradel, $grisDegradel);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.login-box {
    background: white;
    padding: 3rem;
    width: 42rem;
    box-shadow: 0 1.5rem 2.5rem rgba(0, 0, 0, 0.5);
    border-radius: .8rem;
    text-align: center;

    h1 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        font-size: 2.4rem;
        font-weight: bold;
        color: #333;

        &::before {
            content: '';
            display: inline-block;
            width: .4rem;
            height: 2.4rem;
            background: $rojo ;
            margin-right: .8rem;
        }
    }

    h2 {
        margin-bottom: 1rem;
        font-size: 1.8rem;
        font-weight: 500;
        color: #333;
    }

    p {
        margin-bottom: 2rem;
        font-size: 1.4rem;
        color: #666;
    }

    .textbox {
        position: relative;
        margin-bottom: 3rem;

        input {
            width: 100%;
            padding: 1rem;
            background: #f0f0f0;
            border: none;
            outline: none;
            border-radius: .4rem;
            font-size: 1.4rem;
        }
    }

    .btn {
        width: 100%;
        background: $rojo;
        border: none;
        padding: 1rem;
        font-size: 1.6rem;
        border-radius: .4rem;
        color: white;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            background: #710225;
        }
    }
}
.alerta {
    padding: .5rem;
    text-align: center;
    color: $blanco;
    font-weight: $bold;
    text-transform: uppercase;
    margin: 1rem 0;

    &.error {
        background-color: $rojo;
    }

    &.exito {
        background-color: $verde;
    }
}