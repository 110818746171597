



.provedor_grid.a {
    margin-top: 3rem;
    @include grid(2, 1rem);
    input {
        height: 5rem;
    }
}
.provedor_grid.b {
    display: grid;
    grid-template-columns: 1fr .5fr !important;
    gap: 2rem;
}

