.content {
    margin-left: 30rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    margin-bottom: 2rem;

    h2 {
        font-size: 2.4rem;
        margin: 0;
    }
}

.filter-bar {
    display: flex;
    gap: 10px;
    position: sticky;
    top: 0;
    background-color: white; /* Background to ensure it's over table content */
    z-index: 10; /* Ensure it's above other content */
    padding-bottom: 1rem;
    
    select, .search-bar {
        background-color: #f9f9f9;
        border: .1rem solid #ccc;
        padding: .8rem;
        border-radius: .4rem;
        font-size: 1.4rem;
        outline: none;
    }

    .search-button {
        background-color: #007bff;
        color: white;
        padding: .8rem 1.6rem;
        border: none;
        border-radius: .4rem;
        cursor: pointer;
        font-size: 1.4rem;

        &:hover {
            background-color: #0056b3;
        }
    }

    .clear-button {
        background-color: white;
        padding: .8rem 1.6rem;
        border: .1rem solid black;
        border-radius: .4rem;
        cursor: pointer;
        font-size: 1.4rem;

        &:hover {
            background-color: rgb(204, 200, 200);
        }
    }
}

.table-container {
    flex-grow: 1; /* Take up remaining space */
    overflow-x: auto; /* Allow horizontal scrolling */
    overflow-y: auto; /* Allow vertical scrolling */

    table {
        width: 100% !important;
        border-collapse: collapse !important;
        table-layout: auto !important;

        th, td {
            padding: 1.5rem 2rem !important;
            text-align: left !important;
            border-bottom: .1rem solid #ccc !important; 
            font-size: 1.4rem !important;
            color: #333 !important;
        }

        th {
            background-color: #f9f9f9 !important;
            white-space: normal !important;
        }

        td {
            white-space: nowrap !important; /* Prevent text from wrapping */
            i {
                color: #c23636;
                cursor: pointer;
                font-size: 1.8rem;
            }
            .fa-file {
                color: #007bff !important; /* This applies the blue color */
                cursor: pointer;
                font-size: 1.8rem;
            }
        }

        thead {
            position: sticky !important;
            top: 0 !important;   
            z-index: 2;
            tr {
                cursor: not-allowed !important;
            }
        }

        tr {
            cursor: pointer !important;
            &:hover {
                background-color: #f2f2f2!important;
            }
        }

        tr.selected {
            border-left: .4rem solid #007bff!important;
            background-color: #e7f1ff!important;
        }
    }
}
.hidden {
    display: none !important; 
}

.modal {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 300px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.modalButtons {
    margin-top: .5rem;
    @include grid(2, 1rem);
    input {
        margin-bottom: 0;
    }
    .boton-rojo-block {
        background-color: #c23636;;
        color: $blanco;
        border: 0;
    }
}
.btn-excel {
    background-color: #28a745 !important; /* Green background */
    color: white !important;              /* White text */
    border: none;                         /* Remove border */
    margin-right: 5px;                    /* Add some margin between buttons */
    padding: 5px 10px;                    /* Custom padding */
    border-radius: 4px;                   /* Rounded corners */
}

/* Custom PDF Button - Red Background, White Text */
.btn-pdf {
    background-color: #dc3545 !important; /* Red background */
    color: white !important;              /* White text */
    border: none;                         /* Remove border */
    margin-right: 5px;                    /* Add some margin between buttons */
    padding: 5px 10px;                    /* Custom padding */
    border-radius: 4px;                   /* Rounded corners */
}

/* Optional: Add hover effects */
.btn-excel:hover {
    background-color: #218838 !important; /* Darker green on hover */
}

.btn-pdf:hover {
    background-color: #c82333 !important; /* Darker red on hover */
}